import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import XHR from "i18next-xhr-backend";

import translationEng from "./en/translation.json";
import translationHun from "./hu/translation.json";

i18n.use(LanguageDetector)
.use(XHR)
.init({
    resources: {
        en: {
            translations: translationEng
        },
        hu: {
            translations: translationHun
        }
    },
    fallbackLng: "en",
    debug: true,
    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: ".",
    interpolation: {
        escapeValue: false,
        formatSeparator: ","
    },

    react: {
        wait: true
    }
});

export default i18n;