import React, { useContext } from 'react';

import { Box, Grid, Typography } from "@mui/material";

import globalContext from '../context/context'

function TransferTypeButton(props) {
    const { setSelectedType, contextData } = useContext(globalContext);
    return (
        <>
            <Box 
                onClick={() => setSelectedType(props.active)}
                sx={{m: 1, px: 2, py: 2, border: props.active===contextData.selectedType?'2px solid #0B6357':'1px solid #333', borderRadius: '10px', cursor: 'pointer'}}>
                <Grid sx={{display: 'flex', alignItems: 'center'}} container>
                    <Grid  sx={{display: 'flex', alignItems: 'center'}} item>
                        <img width="20px" alt="icon" src={`/images/icons/${props.icon}.png`} />
                    </Grid>
                    <Grid item>
                        <Box sx={{pl: 1, verticalAlign: 'middle'}}>
                            <Typography sx={{fontSize: '.8rem', lineHeight: 'normal'}} variant="subtitle2" component="h2" >{props.title}</Typography>
                            <Typography sx={{fontSize: '.7rem', lineHeight: 'normal', color: '#C4C4C4'}} variant="body2" component="h3" >{props.subtitle}</Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}

export default TransferTypeButton;