import { Box } from "@mui/material";

function FormattedTextField(props) {

    return (
        <Box sx={{
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            border: '1px solid #4f4f4f',
            pt: 2,
            width: '100%',
            pb: 1,
            borderRadius: '5px'
        }}>
            <img height="25px" style={{ paddingLeft: '10px' }} src={props.icon} alt="icon" />
            {props.children}
        </Box>

    );
}

export default FormattedTextField;