import React, { useContext, useRef } from 'react';

import { Box, Grid, Container, Paper } from "@mui/material";

import globalContext from '../context/context'

import BookTypeSwitcher from '../components/BookTypeSwitcher';
import BookingForm from '../components/forms/BookingForm';
import { useWindowScroll } from 'react-use';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


function Book() {
    const { contextData } = useContext(globalContext);
    const { t } = useTranslation();

    const { y } = useWindowScroll();
    let params = useParams();
    const ref = useRef();

    return (
        <>
            <Box sx={{ pt: 0, bgcolor: '#e1f1fc' }}>
                <Grid container>
                    <Grid md={12} item>
                        <Box sx={{
                            pb: 2,
                            backgroundImage: 'url(/images/transfer_02.jpg)',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            height: '60vh',
                            display: 'flex',
                            flexWrap: 'wrap',
                            alignContent: 'space-around'
                        }}>
                        </Box>
                    </Grid>

                    <Grid md={12} item>
                        <Box sx={{ pb: 5 }}>
                            <Container maxWidth="md">
                                <Paper ref={ref} sx={{ borderRadius: '10px', position: 'relative' }}>
                                    <Box sx={{ p: 6 }}>
                                        <BookTypeSwitcher />
                                        <Box sx={{ p: 2 }}>
                                            <BookingForm couponCode={params?.couponCode} selectedType={contextData.selectedType} />
                                        </Box>
                                    </Box>
                                    <Paper sx={{
                                            width: {lg: '200px'}, position: {lg: 'absolute'},
                                            top: (y-ref?.current?.offsetTop+80)<0?0:`${y-ref?.current?.offsetTop+80}px`,
                                            right: '-200px',
                                            borderRadius: '10px',
                                            bgcolor: '#0B6357',
                                            fontWeight: 'bold',
                                            color: '#fff',
                                            textAlign: 'center',
                                            p: 5,
                                            transition: '.5s'
                                         }}>
                                        <Box sx={{ textTransform: 'uppercase', pb: 5, fontSize: '1em' }}>{t("sum")}</Box>
                                        <Box sx={{ textTransform: 'uppercase', pb: 5, fontSize: '1.3em' }}>{contextData.sumPrice}</Box>
                                        <Box sx={{  fontSize: '.6em', fontWeight: 300 }}>
                                            {t("sumtext")}
                                        </Box>
                                    </Paper>
                                </Paper>
                            </Container>
                        </Box>
                    </Grid>


                </Grid>

            </Box>

        </>
    );
}

export default Book;