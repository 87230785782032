import React, { useContext } from 'react';

import { Box, Grid, Typography, Container } from "@mui/material";

import globalContext from '../context/context'
import { useTranslation } from 'react-i18next';

function Contact() {
    const { contextData } = useContext(globalContext);
    const {t} = useTranslation();
    return (
        <>
            <Box sx={{ pt: 0, textAlign: 'center' }}>
                <Grid container>
                    <Grid md={12} item>
                        <Container>
                            <Box sx={{ py: 20, textAlign: 'left' }}>
                                <Typography sx={{
                                    color: '#0B6357',
                                    fontWeight: 'bold',
                                    fontSize: '1.1rem'
                                }}>{t("footer.contactTitle")}</Typography>

                                <Typography sx={{
                                    fontWeight: '200',
                                    fontSize: '.8rem'
                                }}>
                                    {t("footer.contactSubtitle")}
                                </Typography>


                                <Box sx={{ display: 'flex', py: 1, justifyContent: { xs: 'center', md: 'initial' } }}>
                                    <img style={{ paddingRight: '10px' }} src="/images/icons/maps-and-flags.png" alt="flag" height="20px" />
                                    1117 Budapest, Budafoki út 97
                                </Box>
                                <Box sx={{ display: 'flex', py: 1, justifyContent: { xs: 'center', md: 'initial' } }}>
                                    <img style={{ paddingRight: '10px' }} src="/images/icons/phone.png" alt="phone" height="20px" />
                                    +36 1 500 9425
                                </Box>
                                <Box sx={{ display: 'flex', py: 1, justifyContent: { xs: 'center', md: 'initial' } }}>
                                    <img style={{ paddingRight: '10px' }} src="/images/icons/email.png" alt="email" height="20px" />
                                    orders@pulsetrans.com
                                </Box>
                            </Box>
                        </Container>
                    </Grid>
                </Grid>

            </Box>

        </>
    );
}

export default Contact;