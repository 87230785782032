import { Box, CssBaseline } from "@mui/material";
import { grey } from "@mui/material/colors";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Routes, Route } from "react-router-dom";
import './App.css';
import Navigation from "./components/Navigation";
import Home from "./routes/Home";
import GlobalState from "./context/state";
import Footer from "./components/Footer";
import Book from "./routes/Book";
import { SnackbarProvider } from "notistack";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n/i18n";
import { useWindowScroll } from "react-use";
import Aszf from "./routes/Aszf";
import Gdpr from "./routes/Gdpr";
import CookieConsent from "react-cookie-consent";
import Contact from "./routes/Contact";

const lightTheme = createTheme({
  typography: {
    fontFamily: "'Montserrat', sans-serif"
  },
  palette: {
    mode: 'light',
    primary: grey,
    divider: grey[900],
  },
});

function App() {

  const { y } = useWindowScroll();
  
  return (
    <I18nextProvider i18n={i18n}>
      <ThemeProvider theme={lightTheme}>
        <GlobalState>
        <CookieConsent
        buttonStyle={{ backgroundColor: "#0B6357", fontWeight: "bold", color: "#fff" }}
        buttonText="Értettem"
        >Oldalainkon HTTP-sütiket használunk a jobb működésért.</CookieConsent>
          <SnackbarProvider maxSnack={3} autoHideDuration={3000}>
            <CssBaseline />
            <Box sx={{bgcolor: y>100?'rgba(255, 255, 255, .6)':'transparent'}} className="header">
              <Navigation />
            </Box>

            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/book" element={<Book />} />
              <Route path="/aszf" element={<Aszf />} />
              <Route path="/gdpr" element={<Gdpr />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/code/:couponCode" element={<Book />} />
            </Routes>

            <Footer />
            <Box sx={{ textAlign: 'center', fontSize: '11px', py: 4 }}>
              Copyright &#169; {new Date().getFullYear()} Pulse 40 Kft.
              <br />
              We bring you the city!
            </Box>
          </SnackbarProvider>
        </GlobalState>
      </ThemeProvider>
    </I18nextProvider>
  );
}

export default App;
