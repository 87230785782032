import React from 'react';

import { Box, Container, Grid } from "@mui/material";

function Aszf() {

    return (
        <>
            <Box sx={{ pt: 0, textAlign: 'center' }}>
                <Grid container>
                    <Grid md={12} item>
                        <Container>
                            <Box sx={{ py: 20, textAlign: 'left' }}>
                                <h2 style={{textAlign: 'center'}}>Általános szerződési feltételek - Repülőtér személyszállítási szolgáltatás</h2>
                                <Box>
                                    <p>
                                        <ol>
                                            <li>A szolgáltató: A szolgáltató a Pulse 40 Kft. adószám: 25084931-2-13, székhelye: 2724 Újlengyel, Petőfi Sándor utca 48. a továbbiakban a Szolgáltató.</li>
                                            <li>A szolgáltatás leírása: A Szolgáltató repülőtéri transzfer szolgáltatást nyújt Budapest Liszt Ferenc Nemzetközi Repülőtér (továbbiakban BUD) kiinduló vagy végpontjának megadásával. A gépjárműben egy út során csak az adott rendeléshez tartozó személyek tartózkodhatnak.</li>
                                            <li>Szolgáltatás igénybevétele: A Szolgáltató szolgáltatásait a www.pulsetrans.com domain alatt lehet igénybe venni. A szolgáltatás igénybevétele előtt a megrendelőnek a szolgáltatás díját meg kell fizetnie az általános díjszabásnak megfelelően.</li>
                                            <li>Engedélyek és biztosítások: A Szolgáltató rendelkezik az összes szükséges engedéllyel, a gépjárműveink NT számmal és az előírt biztosításokkal.</li>
                                            <li>Felelősség és kártérítés: A Szolgáltató minden esetben igyekszik az ügyfelek számára kényelmes és biztonságos utazást biztosítani, de nem vállal felelősséget az esetleges balesetekért, késésekért, vagy más okokból eredő károkért.</li>
                                            <li>Az adatvédelemről: A Szolgáltató garantálja, hogy a megrendelő adatait bizalmasan kezeli, és nem továbbítja harmadik félnek. Az adatkezelésre vonatkozó részletes információk az adatvédelmi nyilatkozatban találhatók.</li>
                                            <li>Jogi nyilatkozat: A Szolgáltató kizár minden felelősséget a www.pulsetrans.com oldalon található linkekre, valamint a honlap tartalmára történő hivatkozásokért. A honlapra való belépéssel a felhasználók elfogadják a honlap használatára vonatkozó feltételeket.</li>
                                            <li>Az általános szerződési feltételek módosítása: A Szolgáltató fenntartja magának a jogot az általános szerződési feltételek bármikori módosítására. Az esetleges módosításokról az ügyfelek azonnal tájékoztatást kapnak a honlapon keresztül, és az új feltételek hatályba lépésének időpontját is közöljük. Az Ügyfél felelőssége, hogy rendszeresen ellenőrizze az általános szerződési feltételek változásait. Amennyiben az Ügyfél nem ért egyet az új feltételekkel, a szolgáltatás igénybevétele előtt haladéktalanul jelezze azt a Szolgáltató felé. Amennyiben az Ügyfél továbbra is használja a szolgáltatást az új feltételek hatálybalépését követően, azt a Szolgáltató a módosítások elfogadásának tekinti.</li>
                                            <li>Az utazás kezdete és időtartama: Az utazás kezdő időpontja és időtartama a megrendelő által megadott időpontok alapján kerül megállapításra. Az utazás kezdő és végpontja mindig a BUD repülőtér lesz. A szolgáltató fenntartja a jogot arra, hogy ha az utazó nem érkezik meg a megrendelt időpontban, akkor az utazást visszaigazolás nélkül törölheti, és a szolgáltatás díját nem téríti vissza. Az utazás hossza minden esetben az utazási megrendelésben meghatározott időpontoktól függ.</li>
                                            <li>Felelősség és kártérítés: A szolgáltató az utazás során okozott bármilyen kárért vagy veszteségért felelősséget vállal, amely az utazással kapcsolatban merül fel. Az utazó köteles azonnal jelenteni minden olyan problémát, amely az utazás során felmerül. Az utazó felelős minden olyan kárért vagy veszteségért, amely az utazási szerződés szabályainak megsértése miatt merül fel.</li>
                                            <li>Panaszok és reklamációk: Az utazó jogosult panaszokat és reklamációkat előterjeszteni az utazással kapcsolatos szolgáltatásokért. A szolgáltató köteles a panaszokat és reklamációkat megvizsgálni, és azokra megfelelő választ adni.</li>
                                            <li>Adatvédelem és adatkezelés: Az utazó adatainak kezelése a hatályos adatvédelmi jogszabályoknak megfelelően történik. Az utazó hozzájárul ahhoz, hogy a szolgáltató az utazási megrendelés során megadott személyes adatait kezelje és tárolja.</li>
                                            <li>A jelen szerződésben nem szabályozott kérdésekben a magyar jogszabályok az irányadóak. A jelen szerződés bármely rendelkezése jogellenesnek, érvénytelennek vagy hatályon kívül állónak bizonyul, a szerződés többi része érvényben marad.</li>
                                        </ol>
                                    </p>
                                    <p>
                                        A felek jelen szerződést két eredeti példányban kötik, egyet-egyet a felek birtokában.
                                        <br />
                                        <br />
                                        <strong>Szolgáltató:</strong>
                                        <br />
                                        Pulse40 Kft.
                                        <br />
                                        Adószám: 25084931-2-13
                                        <br />
                                        Székhely: 2724 Újlengyel, Petőfi Sándor utca 48.
                                        <br />
                                        Ügyfélszolgálat: +36 1 500 9425
                                        <br />
                                        E-mail: orders@pulsetrans.com

                                    </p>
                                </Box>
                            </Box>
                        </Container>
                    </Grid>
                </Grid>

            </Box>

        </>
    );
}

export default Aszf;