
import { Box, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const titleStyle = {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    pb: 2
}

const contentStyle = {
    fontSize: '1rem',
    fontWeight: '100',
    pb: 2
}

function OtherDrive() {
    const { t } = useTranslation();

    return (
        <>
            <Box sx={{ p: 10, textAlign: 'center' }}>
                <Grid spacing={16} sx={{ display: 'flex', justifyContent: 'space-around' }} container>
                    <Grid md="8" item>
                        <Typography sx={titleStyle} component="h2" variant="h4">
                            {t("otherDrive.col1Title")}
                        </Typography>
                        <Typography sx={contentStyle} component="p" variant="body1">
                            {t("otherDrive.col1Content")}
                        </Typography>
                    </Grid>
                    <Grid sx={{ textAlign: 'left' }} md="6" item>
                        <Typography sx={titleStyle} component="h2" variant="h4">
                            {t("otherDrive.col2Title")}
                        </Typography>
                        <Typography sx={contentStyle} component="p" variant="body1">
                            {t("otherDrive.col2Content")}
                        </Typography>
                    </Grid>
                    <Grid sx={{ textAlign: 'left' }} md="6" item>
                        <Typography sx={titleStyle} component="h2" variant="h4">
                            {t("otherDrive.col3Title")}
                        </Typography>
                        <Typography sx={contentStyle} component="p" variant="body1">
                            {t("otherDrive.col3Content")}
                        </Typography>
                    </Grid>

                </Grid>

            </Box>
        </>
    );
}

export default OtherDrive;