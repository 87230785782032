import React, { useContext } from 'react';

import { Box, Grid, Typography } from "@mui/material";
import QuickActions from "../components/QuickActions";

import globalContext from '../context/context'
import ToAirport from '../components/drives/ToAirport';
import FromAirport from '../components/drives/FromAirport';
import OtherDrive from '../components/drives/OtherDrive';
import Partners from '../components/Partners';
import { useTranslation } from 'react-i18next';

function Home() {
    const { contextData } = useContext(globalContext);
    const {t} = useTranslation();
    return (
        <>
            <Box sx={{ pt: 0, textAlign: 'center' }}>
                <Grid container>
                    <Grid md={12} item>
                        <Box sx={{
                            pb: 2,
                            backgroundImage: 'url(/images/transfer_01.jpg)',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            height: {xs: 'auto', md: '100vh'},
                            display: 'flex',
                            flexWrap: 'wrap',
                            alignContent: 'space-around'
                        }}>
                            <Box sx={{ width: '100%', color: '#292929', pt: 8 }}>
                                <Typography sx={{ fontWeight: '100', fontSize: '1.3rem' }} variant="h5" component="h3" >{t("homePage.fromAirport")}</Typography>
                                <Typography sx={{ fontWeight: 'bold', fontSize: '2.1rem' }} variant="h3" component="h1" >{t("homePage.bringTheCity")}</Typography>
                            </Box>
                            <Box sx={{ width: '100%' }}>
                                <QuickActions />
                            </Box>
                        </Box>
                    </Grid>

                    <Grid md={12} item>
                        <Box sx={{ pb: 5 }}>
                            {contextData.selectedType === "to_airport" && <ToAirport />}
                            {contextData.selectedType === "from_airport" && <FromAirport />}
                            {contextData.selectedType === "other" && <OtherDrive />}
                        </Box>
                    </Grid>

                    { /* <Grid md={12} item>
                        <Box sx={{ pb: 5 }}>
                            <Partners />
                        </Box>
                    </Grid> */ }



                </Grid>

            </Box>

        </>
    );
}

export default Home;