import { Box, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import TransferTypeButton from "./TransferTypeButton";


function BookTypeSwitcher() {

    const {t} = useTranslation();

    return (
        <Box sx={{ p: 2 }}>
            <Box sx={{ px: 1, pb: 2, pt: 1 }}>
                <Typography sx={{ fontSize: '1.3em', fontWeight: 'bold', lineHeight: .8 }} variant="h5" component="h2" >{t("bookTypeSwitcher.bookYourTransfer")}</Typography>
                <Typography sx={{ fontSize: '.8rem', fontWeight: 'normal', color: '#C4C4C4', lineHeight: 1 }} component="subtitle2" >{t("bookTypeSwitcher.transferDirection")}</Typography>
            </Box>
            <Box>
                <Grid container>
                    {[{
                        title: t("bookTypeSwitcher.fromAirportTitle"),
                        subtitle: t("bookTypeSwitcher.fromAirportSubtitle"),
                        icon: "plane",
                        active: 'to_airport'
                    },
                    {
                        title: t("bookTypeSwitcher.toAirportTitle"),
                        subtitle: t("bookTypeSwitcher.toAirportSubtitle"),
                        icon: "plane-down",
                        active: 'from_airport'
                    },
                    {
                        title: t("bookTypeSwitcher.otherTitle"),
                        subtitle: t("bookTypeSwitcher.otherSubtitle"),
                        icon: "distance-to-travel-between-two-points",
                        active: 'other'
                    }].map((b, bk) => (<Grid xs={12} sm={12} md={12} lg={4} key={bk} item>
                        <TransferTypeButton {...b} />
                    </Grid>))}
                </Grid>
                <Box sx={{ m: 1 }}>
                    <Typography sx={{ color: '#C4C4C4', fontSize: '.8rem' }} component="subtitle2" variant="body">
                        {t("bookTypeSwitcher.maximumPassenger")}: 8
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
}

export default BookTypeSwitcher;

