import React from "react";

export default React.createContext({
  contextData: {
    selectedType: 'to_airport',
    sumPrice: '0 EUR'
  },
  setSelectedType: (selectedType) => {},
  setSumPrice: (sumPrice) => {},
  setLanguage: (lang) => {},
});
