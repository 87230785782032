import { Box, Button, Grid } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import globalContext from '../context/context'

const menuStyle = {
    color: '#292929',
    textTransform: 'none',
    fontWeight: '400',
    px: 2
}

function Navigation() {
    const { setLanguage } = useContext(globalContext);
    const { t, i18n } = useTranslation();
    return (
        <>
            <Box sx={{ pb: 2 }}>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Grid item>
                        <Link to="/">
                            <img src="/images/icons/PULSETRANS_logo.png" alt="PULSETRANS_logo" width="120px" />
                        </Link>
                    </Grid>
                    <Grid sx={{ color: '#292929' }} item>
                        <Button sx={{...menuStyle, display: { xs: 'none', md: 'initial' }}} component={Link} to="/">
                            {t("home")}
                        </Button>
                        { /* <Button sx={menuStyle} component={Link} to="/services">
                            {t("services")}
    </Button> */ }
                        <Button sx={{...menuStyle, display: { xs: 'none', md: 'initial' }}} component={Link} to="/contact">
                            {t("contact")}
                        </Button>
                        <Button sx={{...menuStyle, display: { xs: 'none', md: 'initial' }}} component={Link} to="/contact">
                            <img style={{ paddingLeft: '10px', paddingRight: '10px' }} src="/images/icons/phone.png" alt="phone" height="20px" />
                            +36 1 500 9425
                        </Button>
                        <Button sx={menuStyle} onClick={() => setLanguage(i18n.language.includes('hu') ? 'en' : 'hu')} >
                            {i18n.language.includes('hu') ? 'ENG' : 'HUN'}
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}

export default Navigation;