import React, { useState } from "react";
import { withTranslation } from "react-i18next";

import GlobalContext from "./context";

const defaultContextData = {
    selectedType: 'to_airport',
    sumPrice: '0 EUR'
}


const GlobalState = props => {

    const [contextData, setContextData] = useState(defaultContextData);

    const setSelectedType = selectedType => {
        setContextData({...contextData, selectedType})
    };

    const setSumPrice = sumPrice => {
        setContextData({...contextData, sumPrice})
    };

    const setLanguage = lang => {
        props.i18n.changeLanguage(lang)
    };

    return (
        <GlobalContext.Provider
            value={{
                contextData: contextData,
                setSelectedType: setSelectedType,
                setSumPrice: setSumPrice,
                setLanguage: setLanguage
            }}
        >
            {props.children}
        </GlobalContext.Provider>
    );
};

export default withTranslation()(GlobalState);
