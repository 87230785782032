import { Box, Grid, Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';

const menuStyle = {
    width: '100%',
    color: '#292929',
    textTransform: 'none'
}

function Footer() {
    const { t } = useTranslation();
    return (
        <>
            <Box sx={{
                p: 4,
                backgroundImage: 'url(/images/icons/PULSETRANS_GREEN_10.png)',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain'
            }}>
                <Grid container>
                    <Grid item sx={{ textAlign: { xs: 'center', md: 'left' } }} xs={12} md={12}>
                        <img src="/images/icons/PULSETRANS_logo.png" alt="PULSETRANS_logo" width="120px" />
                    </Grid>
                    <Grid sx={{ textAlign: { md: 'left', xs: 'center' } }} item xs={12} md={4}>
                        <Typography sx={{
                            color: '#0B6357',
                            fontWeight: 'bold',
                            fontSize: '1.1rem'
                        }}>{t("footer.contactTitle")}</Typography>

                        <Typography sx={{
                            fontWeight: '200',
                            fontSize: '.8rem'
                        }}>
                            {t("footer.contactSubtitle")}
                        </Typography>


                        <Box sx={{ display: 'flex', py: 1, justifyContent: { xs: 'center', md: 'initial' } }}>
                            <img style={{ paddingRight: '10px' }} src="/images/icons/maps-and-flags.png" alt="flag" height="20px" />
                            1117 Budapest, Budafoki út 97
                        </Box>
                        <Box sx={{ display: 'flex', py: 1, justifyContent: { xs: 'center', md: 'initial' } }}>
                            <img style={{ paddingRight: '10px' }} src="/images/icons/phone.png" alt="phone" height="20px" />
                            +36 1 500 9425
                        </Box>
                        <Box sx={{ display: 'flex', py: 1, justifyContent: { xs: 'center', md: 'initial' } }}>
                            <img style={{ paddingRight: '10px' }} src="/images/icons/email.png" alt="email" height="20px" />
                            orders@pulsetrans.com
                        </Box>

                    </Grid>
                    <Grid sx={{ textAlign: 'center' }} item xs={12} md={4}>
                        <Button sx={menuStyle} component={Link} to="/contact">
                            {t("contact")}
                        </Button>
                        { /* <Button sx={menuStyle} component={Link} to="/">
                            {t("footer.travelConditions")}
                        </Button>
                        <Button sx={menuStyle} component={Link} to="/">
                            {t("footer.faq")}
                    </Button> */ }
                        <Button sx={menuStyle} component={Link} to="/gdpr">
                            {t("footer.gdpr")}
                        </Button>
                        <Button sx={menuStyle} component={Link} to="/aszf">
                            {t("footer.terms")}
                        </Button>
                        <Box sx={{ pt: 4 }}>
                            <a style={{ padding: '10px' }} rel="noreferrer" target="_blank" href="https://facebook.com">
                                <img src="/images/icons/facebook.png" alt="email" height="20px" />
                            </a>
                            <a style={{ padding: '10px' }} rel="noreferrer" target="_blank" href="https://instagram.com">
                                <img src="/images/icons/instagram.png" alt="email" height="20px" />
                            </a>
                        </Box>
                    </Grid>
                    <Grid item md={4}></Grid>
                </Grid>
            </Box>

        </>
    );
}

export default Footer;