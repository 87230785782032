import styled from "@emotion/styled";
import { Box, Grid, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import BookTypeSwitcher from "./BookTypeSwitcher";
import FormattedTextField from "./FormatedTextField";

const NoBorderTextField = styled(TextField)({
    '& input:valid + fieldset': {
        borderWidth: 0,
    },
    '& input:invalid + fieldset': {
        borderWidth: 0,
    },
    '& input:valid:focus + fieldset': {
        borderWidth: 0,
    },
    '& fieldset': {
        borderWidth: 0,
    }
});

const defaultFormValues = {
    foglalasiSzam: ''
}

function QuickActions() {

    const {t} = useTranslation();

    const [formValues, setFormValuesState] = useState(defaultFormValues);

    const setFormValues = (e) => {
        setFormValuesState({ ...formValues, [e.target.name]: e.target.value })
    }

    return (
        <>
            <Box sx={{
                p: 3,
                // border: '2px solid #0B6357',
                backgroundSize: '10% 100%, '
                    + '10% 100%, '
                    + '20% 100%, '
                    + '10% 100%, '
                    + '20% 100%, '
                    + '20% 50%, '
                    + '20% 50%, '
                    + '10% 100%, '
                    + '20% 100%',
                filter: 'drop-shadow(0 0 2px #0B6357) drop-shadow(0 2px 5px rgba(0,0,0,.5))',
                backgroundImage: 'radial-gradient(circle at 0 50%, rgba(255,255,224,0) 1.4em, #fff 0.5em),'
                    + 'radial-gradient(circle at 0 50%, rgba(255,255,224,0) 0, #fff 0),'
                    + 'radial-gradient(circle at 0 50%, rgba(255,255,224,0) 0, #fff 0),'
                    + 'radial-gradient(circle at 0 50%, rgba(255,255,224,0) 0, #fff 0),'
                    + 'radial-gradient(circle at 0 50%, rgba(255,255,224,0) 0, #fff 0),'
                    + 'radial-gradient(circle at 50% 100%, rgba(255,255,224,0) 1.1em, #fff 0.5em),'
                    + 'radial-gradient(circle at 50% 0%, rgba(255,255,224,0) 1.1em, #fff 0.5em),'
                    + 'radial-gradient(circle at 0 50%, rgba(255,255,224,0) 0, #fff 0),'
                    + 'radial-gradient(circle at 100% 50%, rgba(255,255,224,0) 1.4em, #fff 0.5em)',
                backgroundPosition: 'top 0% left 0%,'
                    + 'top 0% left 10%,'
                    + 'top 0% left 20%,'
                    + 'top 0% left 40%,'
                    + 'top 0% left 50%,'
                    + 'bottom 0% left 70%,'
                    + 'top 0% left 70%,'
                    + 'top 0% left 80%,'
                    + 'top right',
                backgroundRepeat: 'no-repeat',
                textAlign: 'left',
                color: '#333',
                mx: 10,
                position: 'relative', mt: 5
            }}>
                <Grid container>
                    <Grid sx={{
                        borderRight: {md: '2px dashed #0B6357'}
                        }} md={8} item>
                        <BookTypeSwitcher />
                    </Grid>

                    <Grid md={4} item>
                        <Box sx={{ p: 2 }}>
                            <Box sx={{ px: 1, pb: 2, pt: 1 }}>
                                <Typography sx={{ fontSize: '1.3em', fontWeight: 'bold', lineHeight: .8 }} variant="h5" component="h2" >{t("quickActions.confirmTransfer")}</Typography>
                                <Typography sx={{ height: '48px', fontSize: '.8rem', fontWeight: 'normal', color: '#C4C4C4', lineHeight: 1, display: 'block', pt: 1 }} component="subtitle2" >{t("quickActions.giveAConfirmNumber")}</Typography>
                                <FormattedTextField icon="/images/icons/scissor.png">
                                    <NoBorderTextField placeholder="Add meg itt" name="foglalasiSzam" value={formValues.foglalasiSzam} onChange={setFormValues} fullWidth size="small" label={t("quickActions.reservationNumber")} variant="outlined" />
                                </FormattedTextField>
                                <Typography sx={{ fontSize: '.8rem', fontWeight: 'normal', color: '#C4C4C4', lineHeight: 1, display: 'block', pt: 1 }} component="subtitle2" >{t("quickActions.feedbackSubtitle")}</Typography>

                            </Box>
                        </Box>
                    </Grid>



                </Grid>
                <Link to="/book">
                    <Box sx={{
                        bgcolor: '#0B6357',
                        color: '#fff',
                        borderRadius: '50%',
                        width: '100px',
                        height: '100px',
                        fontWeight: 'bold',
                        lineHeight: '100px',
                        textAlign: 'center',
                        cursor: 'pointer',
                        position: 'absolute',
                        bottom: '-50px',
                        left: 0,
                        right: 0,
                        margin: 'auto'
                    }}>
                        {t("continue")}
                    </Box>
                </Link>
            </Box>

        </>
    );
}

export default QuickActions;