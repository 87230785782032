import React from 'react';

import { Box, Container, Grid } from "@mui/material";


function Gdpr() {

    return (
        <>
            <Box sx={{ pt: 0, textAlign: 'center' }}>
                <Grid container>
                    <Grid md={12} item>
                        <Container>
                            <Box sx={{ py: 20, textAlign: 'left' }}>
                                <h2 style={{ textAlign: 'center' }}>GDPR NYILATKOZAT</h2>
                                <Box>
                                    <p>
                                        Az alábbiakban az "Üzemeltető" által kezelt személyes adatokat és azok kezelését meghatározó szabályokat határozzuk meg.
                                        <br />
                                        Az Üzemeltető a Pulse 40 Kft. (adószám: 25084931-2-13, székhelye: 2724 Újlengyel, Petőfi Sándor utca 48. e-mail:orders@pulsetrans.com )
                                        <br />
                                        Az Üzemeltető tiszteletben tartja az Ön személyes adatait, és a GDPR rendelkezéseinek megfelelően kezeli azokat. Az Ön személyes adatai a Pulse 40 Kft. által biztosított személyszállítási szolgáltatások igénybevétele során kerülnek kezelésre.
                                    </p>
                                    <p>
                                        Az Ön adatait kizárólag az alábbi célokra használjuk:
                                    </p>
                                    <ul>
                                        <li>Az Ön által igényelt szolgáltatások biztosítása és teljesítése</li>
                                        <li>Az Ön személyes adatainak kezelése és karbantartása az Üzemeltető működésének biztosítása érdekében</li>
                                        <li>Az ügyfélszolgálati igények kiszolgálása és az Önnel történő kommunikáció során</li>
                                        <li>A jogszabályokban előírt kötelezettségek teljesítése érdekében.</li>
                                    </ul>
                                    <p>
                                        Az Ön adatait biztonságosan tároljuk és kezeljük és azokat harmadik fél számára nem adjuk ki vagy használjuk fel. Az adatokhoz csak azok az alkalmazottak férhetnek hozzá, akiknek az adatokra való hozzáférés szükséges a szolgáltatások nyújtása érdekében.
                                        <br />
                                        Az Ön személyes adatait az adatvédelmi rendeletek által biztosított jogok érvényesítése érdekében bármikor módosíthatja vagy törölheti. Az Ön személyes adatainak védelme fontos számunkra, és mindent megteszünk annak érdekében, hogy azokat a lehető legjobban megvédjük.
                                        <br />
                                        Az Ön által megadott adatok kezelése során az Üzemeltető a következő jogi alapokat használja:
                                    </p>
                                    <ul>
                                        <li>A szolgáltatás igénybevétele során az Ön hozzájárulása a személyes adatok kezeléséhez</li>
                                        <li>A szolgáltatás nyújtása érdekében történő szerződéskötés</li>
                                        <li>A jogszabályokban előírt kötelezettség céljából kerülnek feldolgozásra.</li>
                                        <li>Az adatkezelés időtartama a Szolgáltató és az ügyfél között létrejött szerződés teljesítéséig tart, illetve az adatokat jogszabályi előírások szerint tároljuk.</li>
                                    </ul>
                                    <p>
                                        Az ügyfélnek jogában áll az adataihoz való hozzáférés, helyesbítés, törlés és adathordozhatóság igénylésének benyújtása. Az adatvédelmi jogai érvényesítéséhez az ügyfél a szolgaltato@pulsetrans.com e-mail címen keresztül keresheti a Szolgáltatót.
                                        <br />
                                        Az ügyfél tudomásul veszi, hogy a szolgáltatás igénybevételével hozzájárul személyes adatainak feldolgozásához az előbbi célokra. Az ügyfél jogos érdekeit szem előtt tartva a Szolgáltató mindent megtesz az adatbiztonság érdekében és az adatokat harmadik félnek nem továbbítja. Az adatkezelésre vonatkozó további részletekért kérjük, olvassa el Adatvédelmi irányelveinket a www.pulsetrans.com oldalon.
                                    </p>


                                </Box>
                            </Box >
                        </Container >
                    </Grid >
                </Grid >

            </Box >

        </>
    );
}

export default Gdpr;