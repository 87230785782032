import { Box, Checkbox, FormControlLabel, Grid, MenuItem, Switch, TextField, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import { LocalizationProvider, MobileDateTimePicker } from "@mui/x-date-pickers";
import { useContext, useEffect, useState } from "react";
import FormattedTextField from "../FormatedTextField";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import axios from "axios";
import globalContext from '../../context/context'
import useDebounce from "../../hooks/useDebounce";
import 'dayjs/locale/hu';
import dayjs from "dayjs";

import { useSnackbar } from 'notistack';

import { boolean, date, object, string } from 'yup';
import { useTranslation } from "react-i18next";

let orderSchema = object({
    type: string().required("Nincs kiválasztva típus."),
    address: string().required("Cím megadása kötelező").min(3, "Nem megfelelő cím"),
    email: string().required("Email cím megadása kötelező").email("Nem megfelelő email formátum"),
    flightNumber: string().when("type", {
        is: "from_airport",
        then: string().required("Járatszám megadása kötelező").min(3, "Nem megfelelő járatszám")
      }),
    arriveDate: date().typeError("Nem megfelelő dátum").required("Dátum megadása kötelező"),
    // arriveTime: date().typeError("Nem megfelelő időpont").required("Időpont megadása kötelező"),
    fullName: string().required("Név megadása kötelező").min(3, "Nem megfelelő név"),
    phone: string().required("Telefonszám megadása kötelező").min(3, "Nem megfelelő telefonszám"),
    country: string().required("Ország megadása kötelező").min(3, "Nem megfelelő ország"),
    city: string().required("Város megadása kötelező").min(3, "Nem megfelelő város"),
    zip: string().required("Irányítószám megadása kötelező").min(3, "Nem megfelelő irányítószám"),
    street: string().required("Utca megadása kötelező").min(3, "Nem megfelelő utca"),
    confirmed: boolean("ÁSZF elfogadása kötelező").isTrue("ÁSZF elfogadása kötelező")
});

const NoBorderTextField = styled(TextField)({
    '& input:valid + fieldset': {
        borderWidth: 0,
    },
    '& input:invalid + fieldset': {
        borderWidth: 0,
    },
    '& input:valid:focus + fieldset': {
        borderWidth: 0,
    },
    '& fieldset': {
        borderWidth: 0,
    }
});

const defaultFormValues = {
    type: 'to_airport',
    address: '',
    address_from: '',
    address_to: '',
    adults: '1',
    children: '0',
    flightNumber: '',
    needChildSeat: false,
    childSeatNumber: '0',
    boosterSeatNumber: '0',
    arriveDate: null,
    // arriveTime: null,
    suitcase: '0',
    handBag: '0',
    comment: '',
    moreInputActive: false,
    fullName: '',
    email: '',
    phone: '',
    country: '',
    city: '',
    zip: '',
    street: '',
    sameInvoice: true,
    privatePerson: true,
    invoiceFullName: '',
    invoiceEmail: '',
    invoicePhone: '',
    invoiceCountry: '',
    invoiceCity: '',
    invoiceZip: '',
    invoiceStreet: '',
    company: '',
    taxNumber: '',
    confirmed: false,
    haveCoupon: false,
    couponCode: ''
}

function BookingForm(props) {

    const { t } = useTranslation();
    const [formValues, setFormValuesState] = useState(defaultFormValues);
    const { setSumPrice } = useContext(globalContext);

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (props.couponCode && props.couponCode.length) {
            setFormValuesState({ ...defaultFormValues, type: props.selectedType, couponCode: props.couponCode, haveCoupon: true });
        } else {
            setFormValuesState({ ...defaultFormValues, type: props.selectedType, couponCode: '', haveCoupon: false });
        }
    }, [props.selectedType])

    useEffect(() => {
        if (props.couponCode && props.couponCode.length) {
            setFormValuesState({ ...defaultFormValues, couponCode: props.couponCode, haveCoupon: true });
        } else {
            setFormValuesState({ ...defaultFormValues, couponCode: '', haveCoupon: false });
        }
    }, [props.couponCode])

    const convertValuesToFormData = (values) => {
        
        let finalDateFormat = null;

        if (values.arriveDate?.$d) {
            finalDateFormat = values.arriveDate.$d;
            /*
            if (values.arriveTime?.$d) {
                finalDateFormat.setHours(values.arriveTime?.$d.getHours(), values.arriveTime?.$d.getMinutes())
            }
            */
        }

        const invoiceData = formValues.sameInvoice ? {
            billingName: values.fullName,
            email: values.email,
            phone: values.phone,
            billingCountry: values.country,
            billingCity: values.city,
            billingZipCode: values.zip,
            billingAddress: values.street,
            billingCompany: values.company,
            taxNumber: values.taxNumber,
            currency: 'EUR'
        } : {
            billingName: values.invoiceFullName,
            email: values.invoiceEmail,
            phone: values.invoicePhone,
            billingCountry: values.invoiceCountry,
            billingCity: values.invoiceCity,
            billingZipCode: values.invoiceZip,
            billingAddress: values.invoiceStreet,
            billingCompany: values.company,
            taxNumber: values.taxNumber,
            currency: 'EUR'
        }

        return {
            direction: values?.type?.toUpperCase(),
            rawAddress: values.address,
            couponCode: values.haveCoupon && values.couponCode ? values.couponCode : '',
            booking: {
                departure: {
                    rawAddress: values.address_from
                },
                arrival: {
                    rawAddress: values.address_to
                }
            },
            numberOfAdults: values.adults,
            numberOfInfants: values.children,
            flight: {
                flightNumber: values.flightNumber,
                flightTime: finalDateFormat?dayjs(finalDateFormat)?.toISOString():null
            },
            numberOfChildSeats: values.childSeatNumber,
            boosterSeatNumber: values.boosterSeatNumber,
            numberOfLuggages: values.suitcase,
            numberOfBaggages: values.handBag,
            comment: values.comment,
            transUser: {
                name: values.fullName,
                email: values.email,
                phone: values.phone,
                address: {
                    country: values.country,
                    city: values.city,
                    zipCode: values.zip,
                    street: values.street
                }
            },
            billingData: invoiceData
        }
    }

    const debouncedFormValues = useDebounce(formValues, 2000);

    useEffect(() => {
        axios.post('/api/custom/booking/calculate-price', convertValuesToFormData(debouncedFormValues))
            .then(resp => {
                if (resp?.data?.priceInCurrency && resp?.data?.currency) {
                    if(resp?.data?.priceInCurrency && resp?.data?.priceInHUF && parseInt(resp?.data?.priceInCurrency, 10) === parseInt(resp?.data?.priceInHUF, 10)){
                        setSumPrice( parseInt(resp?.data?.priceInHUF, 10) + " HUF");
                    } else{
                        setSumPrice(parseInt(resp.data.priceInCurrency, 10) + " EUR");
                    }
                    
                } else {
                    setSumPrice("0 EUR");
                }

                if (resp?.data?.partner?.address?.rawAddress) {
                    if (!debouncedFormValues.address || !debouncedFormValues.address.length) {
                        setFormValuesState({ ...debouncedFormValues, address: resp?.data?.partner?.address?.rawAddress });
                    }
                }


            }).catch(err => console.error('calculate-price API error'));
    }, [debouncedFormValues])

    const setFormValues = (e, fieldName = '') => {

        if (!e.target) {
            setFormValuesState({ ...formValues, [fieldName]: e })
        } else if (e.target.type === "checkbox") {
            setFormValuesState({ ...formValues, [e.target.name]: !formValues[e.target.name] })
        } else {
            setFormValuesState({ ...formValues, [e.target.name]: e.target.value })
        }

    }

    const submitForm = () => {


        if (formValues.moreInputActive) {

            orderSchema.validate(formValues)
                .then(() => {
                    axios.post('/api/custom/booking/create', convertValuesToFormData(debouncedFormValues))
                        .then(resp => {
                            setFormValuesState({ ...defaultFormValues, type: props.selectedType });
                            enqueueSnackbar(t("bookingForm.successOrder"), { variant: 'success' })
                        }).catch(err => {
                            enqueueSnackbar(t("bookingForm.errorOrder"), { variant: 'error' });
                        });
                }).catch((err) => {
                    enqueueSnackbar(err.errors, { variant: 'error' });
                });


        } else {
            setFormValuesState({ ...formValues, moreInputActive: true });
        }
    }

    const getTitle = () => {

        if (formValues.type === 'to_airport') {
            return t("bookingForm.toAirport");
        } else if (formValues.type === 'from_airport') {
            return t("bookingForm.fromAirport");
        } if (formValues.type === 'other') {
            return t("bookingForm.otherDrive");
        } else {
            return "";
        }

    }

    const getExtraInfo = () => {

        if (formValues.type === 'to_airport') {
            return t("bookingForm.alertToAirport");
        } else if (formValues.type === 'from_airport') {
            return t("bookingForm.alertFromAirport");
        } if (formValues.type === 'other') {
            return "";
        } else {
            return "";
        }

    }


    return (
        <>
            <Box>
                <Box sx={{ px: 1, pb: 2, pt: 1 }}>
                    <Typography sx={{ fontSize: '1.3em', fontWeight: 'bold', lineHeight: .8 }} variant="h5" component="h2" >
                        {getTitle()}
                    </Typography>
                    <Typography sx={{ fontSize: '.8rem', fontWeight: 'normal', color: '#C4C4C4', lineHeight: 1 }} component="subtitle2" >
                        {t("bookingForm.subtitle")}
                    </Typography>
                </Box>

                <Grid spacing={1} container>
                    {formValues.type === 'other' ?
                        <>
                            <Grid md={12} item>
                                <FormattedTextField icon="/images/icons/maps-and-flags.png">
                                    <NoBorderTextField name="address_from" value={formValues.address_from} onChange={setFormValues} fullWidth size="small" label={t("bookingForm.addressFrom")} variant="outlined" />
                                </FormattedTextField>
                            </Grid>
                            <Grid md={12} item>
                                <FormattedTextField icon="/images/icons/maps-and-flags.png">
                                    <NoBorderTextField name="address_to" value={formValues.address_to} onChange={setFormValues} fullWidth size="small" label={t("bookingForm.addressTo")} variant="outlined" />
                                </FormattedTextField>
                            </Grid>
                        </>
                        : <Grid md={12} xs={12} item>
                            <FormattedTextField icon="/images/icons/maps-and-flags.png">
                                <NoBorderTextField name="address" value={formValues.address} onChange={setFormValues} fullWidth size="small" label={t("bookingForm.address")} variant="outlined" />
                            </FormattedTextField>
                        </Grid>}

                    <Grid md={12} xs={12} item>
                        <Box sx={{ py: 2 }}>
                            <FormControlLabel
                                name="haveCoupon"
                                checked={formValues.haveCoupon}
                                onChange={setFormValues}
                                control={<Switch color="primary" />}
                                label={t("bookingForm.haveCoupon")}
                                labelPlacement="start"
                            />
                        </Box>
                    </Grid>
                    {formValues.haveCoupon && <>
                        <Grid md={12} item>
                            <FormattedTextField icon="/images/icons/maps-and-flags.png">
                                <NoBorderTextField name="couponCode" value={formValues.couponCode} onChange={setFormValues} fullWidth size="small" label={t("bookingForm.couponCode")} variant="outlined" />
                            </FormattedTextField>
                        </Grid>

                    </>}

                    <Grid md={4} xs={12} item>
                        <FormattedTextField icon="/images/icons/user.png">
                            <NoBorderTextField
                                select
                                variant="outlined"
                                size="small"
                                label={t("bookingForm.adults")}
                                fullWidth
                                name="adults"
                                value={formValues.adults}
                                onChange={setFormValues}
                                defaultValue={1}
                            >
                                {[1, 2, 3, 4, 5, 6, 7, 8].map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </NoBorderTextField>
                        </FormattedTextField>
                    </Grid>

                    <Grid md={4} xs={12} item>
                        <FormattedTextField icon="/images/icons/children.png">
                            <NoBorderTextField
                                select
                                variant="outlined"
                                size="small"
                                label={t("bookingForm.children")}
                                fullWidth
                                name="children"
                                value={formValues.children}
                                onChange={setFormValues}
                                defaultValue={0}
                            >
                                {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </NoBorderTextField>
                        </FormattedTextField>
                    </Grid>
                    <Grid md={12} xs={12} item>
                        <Box sx={{ py: 2 }}>
                            <FormControlLabel
                                name="needChildSeat"
                                checked={formValues.needChildSeat}
                                onChange={setFormValues}
                                control={<Switch color="primary" />}
                                label={t("bookingForm.needChildSeat")}
                                labelPlacement="start"
                            />
                        </Box>
                    </Grid>

                    {formValues.needChildSeat && <>
                        <Grid md={12} xs={12} item>
                            <FormattedTextField icon="/images/icons/baby-boy.png">
                                <NoBorderTextField
                                    select
                                    variant="outlined"
                                    size="small"
                                    label={t("bookingForm.childSeatNumber")}
                                    fullWidth
                                    name="childSeatNumber"
                                    value={formValues.childSeatNumber}
                                    onChange={setFormValues}
                                    defaultValue={0}
                                >
                                    {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((option) => (
                                        <MenuItem key={option} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </NoBorderTextField>
                            </FormattedTextField>
                        </Grid>
                        <Grid md={12} xs={12} item>
                            <FormattedTextField icon="/images/icons/international-childrens-day.png">
                                <NoBorderTextField
                                    select
                                    variant="outlined"
                                    size="small"
                                    label={t("bookingForm.boosterSeatNumber")}
                                    fullWidth
                                    name="boosterSeatNumber"
                                    value={formValues.boosterSeatNumber}
                                    onChange={setFormValues}
                                    defaultValue={0}
                                >
                                    {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((option) => (
                                        <MenuItem key={option} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </NoBorderTextField>
                            </FormattedTextField>
                        </Grid>
                    </>}

                    <Grid md={12} xs={12} item>
                        <Box sx={{ py: 2 }}>
                            {getExtraInfo()}
                        </Box>
                    </Grid>

                    <LocalizationProvider adapterLocale="en" dateAdapter={AdapterDayjs}>

                        <Grid md={12} xs={12} item>
                            <FormattedTextField icon="/images/icons/calendar.png">
                                <MobileDateTimePicker
                                    label={formValues.type === 'from_airport'?t("bookingForm.arriveDate"):t("bookingForm.departureDate")}
                                    inputFormat="YYYY-MM-DD HH:mm"
                                    value={formValues.arriveDate}
                                    fullWidth
                                    ampm={false}
                                    ampmInClock={false}
                                    onChange={(date) => setFormValues(date, 'arriveDate')}
                                    renderInput={(params) => <NoBorderTextField fullWidth {...params} />}
                                />
                            </FormattedTextField>
                        </Grid>
                        { /* <Grid md={12} xs={12} item>
                            <FormattedTextField icon="/images/icons/time-left.png">
                                <MobileTimePicker
                                    label={formValues.type === 'from_airport'?t("bookingForm.arriveTime"):t("bookingForm.departureTime")}
                                    value={formValues.arriveTime}
                                    ampm={false}
                                    ampmInClock={false}
                                    fullWidth
                                    onChange={(date) => setFormValues(date, 'arriveTime')}
                                    renderInput={(params) => <NoBorderTextField fullWidth {...params} />}
                                />

                            </FormattedTextField>
                        </Grid> */ }
                    </LocalizationProvider>

                    <Grid md={12} xs={12} item>
                        <FormattedTextField icon="/images/icons/ticket-flight.png">
                            <NoBorderTextField name="flightNumber" value={formValues.flightNumber} onChange={setFormValues} fullWidth size="small" label={t("bookingForm.flightNumber")} variant="outlined" />
                        </FormattedTextField>
                    </Grid>

                    <Grid md={4} xs={12} item>
                        <FormattedTextField icon="/images/icons/luggage.png">
                            <NoBorderTextField
                                select
                                variant="outlined"
                                size="small"
                                label={t("bookingForm.suitcase")}
                                fullWidth
                                name="suitcase"
                                value={formValues.suitcase}
                                onChange={setFormValues}
                                defaultValue={0}
                            >
                                {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </NoBorderTextField>
                        </FormattedTextField>
                    </Grid>

                    <Grid md={4} xs={12} item>
                        <FormattedTextField icon="/images/icons/carry-on.png">
                            <NoBorderTextField
                                select
                                variant="outlined"
                                size="small"
                                label={t("bookingForm.handBag")}
                                fullWidth
                                name="handBag"
                                value={formValues.handBag}
                                onChange={setFormValues}
                                defaultValue={0}
                            >
                                {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </NoBorderTextField>
                        </FormattedTextField>
                    </Grid>

                    <Grid md={4} xs={12} item>
                        <FormattedTextField icon="/images/icons/information-button.png">
                            <NoBorderTextField name="comment" value={formValues.comment} onChange={setFormValues} fullWidth size="small" label={t("bookingForm.comment")} variant="outlined" />
                        </FormattedTextField>
                    </Grid>


                    {formValues.moreInputActive && <>
                        <Grid item xs={12} md={12}>
                            <Box sx={{ pt: 5, pb: 1 }}>
                                <Typography sx={{ fontSize: '1.3em', fontWeight: 'bold', lineHeight: .8 }} variant="h5" component="h2" >
                                    {t("bookingForm.personalData")}
                                </Typography>
                                <Typography sx={{ fontSize: '.8rem', fontWeight: 'normal', color: '#C4C4C4', lineHeight: 1 }} component="subtitle2" >
                                    {t("bookingForm.personalDataText")}
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid md={6} xs={12} item>
                            <FormattedTextField icon="/images/icons/user.png">
                                <NoBorderTextField
                                    name="fullName"
                                    value={formValues.fullName}
                                    onChange={setFormValues}
                                    fullWidth
                                    placeholder="Add meg itt"
                                    size="small"
                                    label={t("bookingForm.fullName")}
                                    variant="outlined" />
                            </FormattedTextField>
                        </Grid>
                        <Grid md={6} xs={12} item>
                            <FormattedTextField icon="/images/icons/email.png">
                                <NoBorderTextField
                                    name="email"
                                    value={formValues.email}
                                    onChange={setFormValues}
                                    fullWidth
                                    size="small"
                                    label={t("bookingForm.email")}
                                    placeholder="Add meg itt"
                                    variant="outlined" />
                            </FormattedTextField>
                        </Grid>
                        <Grid md={6} xs={12} item>
                            <FormattedTextField icon="/images/icons/phone.png">
                                <NoBorderTextField
                                    name="phone"
                                    value={formValues.phone}
                                    onChange={setFormValues}
                                    fullWidth
                                    size="small"
                                    label={t("bookingForm.phone")}
                                    placeholder="Add meg itt"
                                    variant="outlined" />
                            </FormattedTextField>
                        </Grid>
                        <Grid md={6} xs={12} item>
                            <FormattedTextField icon="/images/icons/maps-and-flags.png">
                                <NoBorderTextField
                                    name="country"
                                    value={formValues.country}
                                    onChange={setFormValues}
                                    fullWidth
                                    size="small"
                                    label={t("bookingForm.country")}
                                    placeholder="Add meg itt"
                                    variant="outlined" />
                            </FormattedTextField>
                        </Grid>
                        <Grid md={6} xs={12} item>
                            <FormattedTextField icon="/images/icons/maps-and-flags.png">
                                <NoBorderTextField
                                    name="city"
                                    value={formValues.city}
                                    onChange={setFormValues}
                                    fullWidth
                                    size="small"
                                    label={t("bookingForm.city")}
                                    placeholder="Add meg itt"
                                    variant="outlined" />
                            </FormattedTextField>
                        </Grid>
                        <Grid md={6} xs={12} item>
                            <FormattedTextField icon="/images/icons/maps-and-flags.png">
                                <NoBorderTextField
                                    name="zip"
                                    value={formValues.zip}
                                    onChange={setFormValues}
                                    fullWidth
                                    size="small"
                                    label={t("bookingForm.zip")}
                                    placeholder="Add meg itt"
                                    variant="outlined" />
                            </FormattedTextField>
                        </Grid>
                        <Grid md={12} xs={12} item>
                            <FormattedTextField icon="/images/icons/maps-and-flags.png">
                                <NoBorderTextField
                                    name="street"
                                    value={formValues.street}
                                    onChange={setFormValues}
                                    fullWidth
                                    size="small"
                                    label={t("bookingForm.street")}
                                    placeholder="Add meg itt"
                                    variant="outlined" />
                            </FormattedTextField>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Box sx={{ pt: 5, pb: 1 }}>
                                <Typography sx={{ fontSize: '1.3em', fontWeight: 'bold', lineHeight: .8 }} variant="h5" component="h2" >
                                    {t("bookingForm.invoiceData")}
                                </Typography>
                                <Typography sx={{ fontSize: '.8rem', fontWeight: 'normal', color: '#C4C4C4', lineHeight: 1 }} component="subtitle2" >
                                    {t("bookingForm.invoiceDataText")}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid md={8} xs={12} item>
                            <Box sx={{ py: 2, textAlign: 'left' }}>
                                <FormControlLabel
                                    name="sameInvoice"
                                    checked={formValues.sameInvoice}
                                    onChange={setFormValues}
                                    control={<Switch color="primary" />}
                                    label={t("bookingForm.sameInvoice")}
                                    labelPlacement="start"
                                />
                            </Box>
                        </Grid>
                        <Grid md={4} xs={12} item>
                            <Box sx={{ py: 2, textAlign: { md: 'right', xs: 'left' } }}>
                                <FormControlLabel
                                    name="privatePerson"
                                    checked={formValues.privatePerson}
                                    onChange={setFormValues}
                                    control={<Switch color="primary" />}
                                    label={t("bookingForm.privatePerson")}
                                    labelPlacement="start"
                                />
                            </Box>
                        </Grid>

                        {!formValues.privatePerson && <>

                            <Grid md={6} xs={12} item>
                                <FormattedTextField icon="/images/icons/maps-and-flags.png">
                                    <NoBorderTextField
                                        name="company"
                                        value={formValues.company}
                                        onChange={setFormValues}
                                        fullWidth
                                        placeholder="Add meg itt"
                                        size="small"
                                        label={t("bookingForm.company")}
                                        variant="outlined" />
                                </FormattedTextField>
                            </Grid>
                            <Grid md={6} xs={12} item>
                                <FormattedTextField icon="/images/icons/maps-and-flags.png">
                                    <NoBorderTextField
                                        name="taxNumber"
                                        value={formValues.taxNumber}
                                        onChange={setFormValues}
                                        fullWidth
                                        size="small"
                                        label={t("bookingForm.taxNumber")}
                                        placeholder="Add meg itt"
                                        variant="outlined" />
                                </FormattedTextField>
                            </Grid>
                        </>}

                        {!formValues.sameInvoice && <>

                            <Grid md={6} xs={12} item>
                                <FormattedTextField icon="/images/icons/user.png">
                                    <NoBorderTextField
                                        name="invoiceFullName"
                                        value={formValues.invoiceFullName}
                                        onChange={setFormValues}
                                        fullWidth
                                        placeholder="Add meg itt"
                                        size="small"
                                        label={t("bookingForm.fullName")}
                                        variant="outlined" />
                                </FormattedTextField>
                            </Grid>
                            <Grid md={6} xs={12} item>
                                <FormattedTextField icon="/images/icons/email.png">
                                    <NoBorderTextField
                                        name="invoiceEmail"
                                        value={formValues.invoiceEmail}
                                        onChange={setFormValues}
                                        fullWidth
                                        size="small"
                                        label={t("bookingForm.email")}
                                        placeholder="Add meg itt"
                                        variant="outlined" />
                                </FormattedTextField>
                            </Grid>
                            <Grid md={6} xs={12} item>
                                <FormattedTextField icon="/images/icons/phone.png">
                                    <NoBorderTextField
                                        name="invoicePhone"
                                        value={formValues.invoicePhone}
                                        onChange={setFormValues}
                                        fullWidth
                                        size="small"
                                        label={t("bookingForm.phone")}
                                        placeholder="Add meg itt"
                                        variant="outlined" />
                                </FormattedTextField>
                            </Grid>
                            <Grid md={6} xs={12} item>
                                <FormattedTextField icon="/images/icons/maps-and-flags.png">
                                    <NoBorderTextField
                                        name="invoiceCountry"
                                        value={formValues.invoiceCountry}
                                        onChange={setFormValues}
                                        fullWidth
                                        size="small"
                                        label={t("bookingForm.country")}
                                        placeholder="Add meg itt"
                                        variant="outlined" />
                                </FormattedTextField>
                            </Grid>
                            <Grid md={6} xs={12} item>
                                <FormattedTextField icon="/images/icons/maps-and-flags.png">
                                    <NoBorderTextField
                                        name="invoiceCity"
                                        value={formValues.invoiceCity}
                                        onChange={setFormValues}
                                        fullWidth
                                        size="small"
                                        label={t("bookingForm.city")}
                                        placeholder="Add meg itt"
                                        variant="outlined" />
                                </FormattedTextField>
                            </Grid>
                            <Grid md={6} xs={12} item>
                                <FormattedTextField icon="/images/icons/maps-and-flags.png">
                                    <NoBorderTextField
                                        name="invoiceZip"
                                        value={formValues.invoiceZip}
                                        onChange={setFormValues}
                                        fullWidth
                                        size="small"
                                        label={t("bookingForm.zip")}
                                        placeholder="Add meg itt"
                                        variant="outlined" />
                                </FormattedTextField>
                            </Grid>
                            <Grid md={12} xs={12} item>
                                <FormattedTextField icon="/images/icons/maps-and-flags.png">
                                    <NoBorderTextField
                                        name="invoiceStreet"
                                        value={formValues.invoiceStreet}
                                        onChange={setFormValues}
                                        fullWidth
                                        size="small"
                                        label={t("bookingForm.street")}
                                        placeholder="Add meg itt"
                                        variant="outlined" />
                                </FormattedTextField>
                            </Grid>
                        </>}

                        <Grid md={12} xs={12} item>
                            <Box sx={{ py: 2, display: 'flex' }}>
                                <Checkbox checked={formValues.confirmed} name="confirmed" onChange={setFormValues} icon={<CheckCircleOutlineRoundedIcon />} checkedIcon={<CheckCircleRoundedIcon />} />
                                <Box>
                                    {t("bookingForm.confirm")}
                                </Box>
                            </Box>
                        </Grid>

                    </>}



                    <Grid item xs={12} md={12}>
                        <Box sx={{ mb: 2, mt: 8, textAlign: 'center' }}>
                            <Box
                                onClick={() => submitForm()}
                                sx={{
                                    bgcolor: '#0B6357',
                                    color: '#fff',
                                    borderRadius: '50%',
                                    width: '100px',
                                    height: '100px',
                                    fontWeight: 'bold',
                                    lineHeight: '100px',
                                    textAlign: 'center',
                                    cursor: 'pointer',
                                    margin: 'auto'
                                }}>
                                {formValues.moreInputActive ? t("book") : t("continue")}
                            </Box>
                        </Box>
                        <Box sx={{ mb: 2, mt: 8, textAlign: 'center' }}>

                            {t("bookingForm.footer1")}
                            <br />
                            {t("bookingForm.footer2")}
                            <br />
                            {t("bookingForm.footer3")}
                        </Box>
                    </Grid>

                </Grid>

            </Box>
        </>
    );
}

export default BookingForm;